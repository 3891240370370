import React from "react"
import { Section } from "../Containers"
import { Button } from "../Button"
import { Text } from "../Core"

const SmallCTA = ({
  heading = `Get Your Free <br />
            Wisdom Tooth Consult!`,
  href = "/wisdom-teeth-consult-np/"
}) => {
  return (
    <Section smallSpacing>
      <div className="cta__small">
        <div>
          <Text as="h3" className="mx-auto" text={heading} />
        </div>
        <div>
          <Button
            buttonText="Learn More"
            appearance="white"
            className="white mt-0 mx-auto--mobile"
            destination="internal"
            href={href}
          />
        </div>
      </div>
    </Section>
  )
}

export default SmallCTA
